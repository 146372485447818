import {Form, Drawer,Button } from 'antd';
import React from 'react';
import { useState } from 'react';
import { renderInput, renderTextField } from '../../../component/form/form';
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };
const ChangePass = ({placement,visible,fnc,id, passFnc}) => {
    const onFinish = async (values) => {
        const code = await passFnc(id,values);
        if(code === 201 ){
            fnc()
          }
};

const onFinishFailed = (errorInfo) => {
console.log('Failed:', errorInfo);
};
    return ( 

        <Drawer
              title="Change Password"
              placement={placement}
              closable={false}
              onClose={fnc}
              visible={visible}
              key={placement}
              height={300}
            >
              <Form
                name="basic"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                style={{alignSelf: 'center'}}
                requiredMark={false}
              >
              {renderInput('old_password', 'Old Password',
                [
                  { required: true, message: 'This field is required' },
                  { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
                ], '', 'password'
              )}
              {renderInput('new_password', 'New Password',
                [
                  { required: true, message: 'This field is required' },
                  { pattern: /^[A-Za-z\d@$!%*?&]{8,50}$/, message: 'Minimum 8 character, allowed special characters are @$!%*?&' }
                ], '', 'password'
              )}
              <Form.Item {...tailLayout}> 
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
              </Form>
            </Drawer>
     );
}

export default ChangePass;