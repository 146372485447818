import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Col, Tooltip    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getTranslators, createTranslator, updateTranslator, deleteTranslator, getTranslatorById } from "../../../actions/book";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, } from '../../../component/form/form';
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;

const TranslatorList = ({ getTranslators, updateTranslator, deleteTranslator, getTranslatorById, createTranslator }) => {
  const [ID, setID] = useState('')
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [cform] = Form.useForm();

  const getData = async () => {
    const res = await getTranslators()
    setData(res);
  }
  useEffect(() => {
    getData()
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleEdit = async (item) => {
    setID(item?.id)
    const res =  await getTranslatorById(item?.id);
    if(res) {
      setIsEditModalOpen(true);
      form.setFieldsValue({
        name: res?.name,
        status: res?.status,
      });
    }
    
  }
  
  const handleDelete = async (item) => {
    const res =  await deleteTranslator(item?.id);
    if(res === 201){
      getData();
    };
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (id, name, status, action) => {
    return {id, name, status, action};
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.name, 
        item?.status ? 'Active' : 'Inactive', 
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            type="danger"
            onConfirm={() => handleDelete(item)}
          >
            <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
          </Popconfirm>
          {/* <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp; */}
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createTranslator(values);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateTranslator(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Translator List</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Translator List"
        subTitle="Book Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Table columns={columns} pagination={true} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />

      <Modal 
        title="Create Translator" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          {renderInput('name', 'Name', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }
          
          {renderSelect('status', 'Status', [
              { required: true, message: 'This field is required!' },
            ], "", status, 'single')
          }
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Translator" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {renderInput('name', 'Name', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }
          {renderSelect('status', 'Status', [
              { required: true, message: 'This field is required!' },
            ], "", status, 'single')
          }
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
    </Content>
   );
}
 
export default connect(null, {getTranslators, createTranslator, updateTranslator,deleteTranslator, getTranslatorById})(TranslatorList);