import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer, Modal, Form, Pagination, Row, Upload, Col, Tooltip, Select    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined,  EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined, UploadOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getBook, createBook, updateBook, deleteBook, getBookById, helperAPI } from "../../../actions/book";
import { Link } from 'react-router-dom';
import Resizer from 'react-image-file-resizer';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderDate, renderInput, renderSelect, renderTextField, } from '../../../component/form/form';
import axios from 'axios';
import moment from 'moment';
import '../style.css'
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const BookList = ({ getBook, updateBook, deleteBook, getBookById, helperAPI, createBook }) => {
  const [ID, setID] = useState('')
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  const [imagefiles, setImagefiles] = useState('');
  const [bookfiles, setBookfiles] = useState('');
  const [bookdemofiles, setBookdemofiles] = useState('');
  const [type, setType] = useState('');
  const [allAuthor, setAllAuthor] = useState([]);
  const [allTranslator, setAllTranslator] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allPublisher, setAllPublisher] = useState([]);

  const createOption = (value, label) => {
    return {value, label};
  };

  const getData = async () => {
    const res = await getBook()
    setData(res);

    const options = await helperAPI();
    const author = [
      options
        ? options[0]?.author?.map((item, key) => createOption(
          item?.name, 
          item?.name, 
          ))
        : "",
    ];
    const publisher = [
      options
        ? options[0]?.publisher?.map((item, key) => createOption(
          item?.name, 
          item?.name, 
          ))
        : "",
    ];
    const translator = [
      options
        ? options[0]?.translator?.map((item, key) => createOption(
          item?.name, 
          item?.name, 
          ))
        : "",
    ];
    const category = [
      options
        ? options[0]?.bookCategory?.map((item, key) => createOption(
          item?.name, 
          item?.name, 
          ))
        : "",
    ];
    
    setAllAuthor(...author)
    setAllPublisher(...publisher)
    setAllTranslator(...translator)
    setAllCategory(...category)
  }
  useEffect(() => {
    getData()
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const props = {
    name: 'file',
    action: 'https://api.hidayahbooks.hidayahsmart.solutions/v1/banner-management/banner/upload',
    headers: {
      'content-type': 'multiform/data',
      authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    
    onRemove: (file) => {
        const res = axios('https://api.hidayahbooks.hidayahsmart.solutions/v1/banner-management/banner/delete',
        {
            headers: {
                'content-type': 'multiform/data',
                'authorization': `Bearer ${localStorage.getItem("token")}`
            },
            data: {
              file: file?.response?.name,
            },
            method: 'delete'
        
        })
    },
  };
  
  const handleEdit = async (item) => {
    setID(item?.uuid)
    const res =  await getBookById(item?.uuid);
    if(res) {
      setIsEditModalOpen(true);
      setType(res?.state)
      form.setFieldsValue({
        name: res?.name,
        translator_name: res?.translator_name,
        publisher_name: res?.publisher_name,
        author_name: res?.author_name,
        book_categories: res?.book_categories,
        state: res?.state,
        price: res?.price,
        language: res?.language,
        status: res?.status,
        total_page: res?.total_page,
        orginal_date: res?.orginal_date ? moment(res?.orginal_date) : null,
        store_date: res?.store_date ? moment(res?.store_date) : null,
        summary: res?.summary,
      });
    }
    
  }
  
  const handleDelete = async (item) => {
    const res =  await deleteBook(item?.uuid);
    if(res === 201){
      getData();
    };
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const getImage = async (item) => {
    const res =  await getBookById(item?.id);
   return res;
    
  }
  
  const createData = (id, name, author_name, image_name_f, status, action) => {
    return {id, name, author_name, image_name_f, status, action};
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Author Name',
      dataIndex: 'author_name',
      key: 'author_name',
      width: '20%',
      ...getColumnSearchProps('author_name'),
    },
    {
      title: 'Image',
      dataIndex: 'image_name_f',
      key: 'image_name_f',
      width: '20%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.data?.name, 
        item?.data?.author_name, 
        <img className='img' src ={`https://api.hidayahbooks.hidayahsmart.solutions/static/book_cover/${item?.data?.image_name_f}`} width = '30px' />,
        item?.data?.status ? 'Active' : 'Inactive', 
        <>
          <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item?.data)}>Edit</Button> &nbsp;
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            okText="Yes"
            cancelText="No"
            type="danger"
            onConfirm={() => handleDelete(item?.data)}
          >
            <Button size="small" type="danger" icon={<DeleteOutlined  />} >Delete</Button> &nbsp;
          </Popconfirm>
          {/* <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp; */}
        </>
        ))
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createBook(values, imagefiles, bookfiles, bookdemofiles);
    if(res ===  201){
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateBook(values, ID);
    if(res ===  201){
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const state = [
    {value: 'free', label: 'Free'},
    {value: 'premium', label: 'Premium'},
  ]
  const language = [
    {value: 'bangla', label: 'Bangla'},
    {value: 'arabic', label: 'Arabic'},
    {value: 'urdu', label: 'Urdu'},
    {value: 'english', label: 'English'},
  ]

  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]

  
  const handleImageChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    if(
      ((e.target.files[0]?.name).split('.')[1]) === 'jpg' ||
      ((e.target.files[0]?.name).split('.')[1]) === 'jpeg' ||
      ((e.target.files[0]?.name).split('.')[1]) === 'JPG' ||
      ((e.target.files[0]?.name).split('.')[1]) === 'JPEG' ||
      ((e.target.files[0]?.name).split('.')[1]) === 'PNG' ||
      ((e.target.files[0]?.name).split('.')[1]) === 'png'
      ) {
        var type= e.target.files[0].type;
        if (e.target.files && e.target.files[0]) {
          var fsize = e.target.files[0].size/1024;
          
                try {
                    Resizer.imageFileResizer(
                    e.target.files[0],
                    450,
                    300,
                    'JPG',
                    100,
                    0,
                    uri => {
                      setImagefiles(uri) 
                    },
                    'blob',
                    450,
                    300,
                    );
                    }   
                    catch(err) {
                    }
        }
      }
      else {
        cform.setFieldsValue({
          imagefiles: '',
        });
        alert('Invalid image format.');
        return;
      }
    }
  
  const handleFileChange = (event) => {
    // let blob = new Blob([e.target.value], {type: 'ePub'});
    if(((event.target.files[0]?.name).split('.')[1]) === 'epub')
      {
        if(event.target.name === 'bookdemofiles') {
          setBookdemofiles(event.target.files[0])
        }
        else {
          setBookfiles(event.target.files[0])
        }
      }
      else{
        if(event.target.name === 'bookdemofiles') {
          cform.setFieldsValue({
            bookdemofiles: '',
          });
        }
        else {
          cform.setFieldsValue({
            bookfiles: '',
          });
        }
        alert('Invalid file format. Accept only epub');
        return;
      }
    }
  

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Books</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Books"
        subTitle="Book Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Table columns={columns} pagination={true} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />

      <Modal 
        title="Add Book" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          {renderInput('name', 'Name', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }
          
          {renderSelect('translator_name', 'Translator', [
              { required: true, message: 'This field is required!' },
            ], "", allTranslator, 'single')
          }
          
          {renderSelect('publisher_name', 'Publisher', [
              { required: true, message: 'This field is required!' },
            ], "", allPublisher, 'single')
          }

          {renderSelect('author_name', 'Author', [
              { required: true, message: 'This field is required!' },
            ], "", allAuthor, 'single')
          }

          {renderSelect('book_categories', 'Categories', [
              { required: true, message: 'This field is required!' },
            ], "", allCategory, 'single')
          }

          <Form.Item
              label={"Cover Photo"}
              name="imagefiles"
              rules={[
              {
                  required: true,
                  message: "This field is required",
              },
              ]}
          >
              <Input type="file" name="imagefiles" onChange={(e)=>handleImageChange(e)}/>
          </Form.Item>
          
          <Form.Item
              label={"Upload Book"}
              name="bookfiles"
              rules={[
              {
                  required: true,
                  message: "This field is required",
              },
              ]}
          >
              <Input type="file" name="bookfiles" onChange={(e)=> handleFileChange(e)} />
          </Form.Item>

          <Form.Item
            name="state"
            label="Type"
            rules={[{ required: true, message: 'This field is required!' }]}
          >
            <Select
                showSearch
                placeholder="Select type"
                size="middle"
                allowClear
                mode='single'
                onChange={(e) => setType(e)}
            >
                {state.map(item => (
                    <Option value={item.value}>{item.label}</Option>
                ))}
            </Select>
          </Form.Item> 

          {type === 'premium' ?
          <>
            {renderInput('price', 'Price', [
                { required: false, message: 'This field is required!' },
              ], 'large', 'number')
            }
            <Form.Item
                label={"Upload Demo Book"}
                name="bookdemofiles"
                rules={[
                {
                    required: true,
                    message: "This field is required",
                },
                ]}
            >
                <Input type="file" name="bookdemofiles" onChange={(e)=> handleFileChange(e)}  />
            </Form.Item>
          
          </>
          :null}

          {renderSelect('language', 'Language', [
              { required: true, message: 'This field is required!' },
            ], "", language, 'single')
          }

          {renderSelect('status', 'Status', [
              { required: false, message: 'This field is required!' },
            ], "", status, 'single')
          }


          {renderInput('total_page', 'Total Page', [
              { required: true, message: 'This field is required!' },
            ], 'large', 'number')
          }

          {renderDate('store_date', 'Store Date', [
              { required: true, message: 'This field is required!' },
            ], 'large')
          }

          {renderDate('orginal_date', 'Orginal Date', [
              { required: false, message: 'This field is required!' },
            ], 'large')
          }


          {renderTextField('summary', 'Summary', [
              { required: false, message: 'This field is required!' },
            ], 'large')
          }
          
          
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit book" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
        {renderInput('name', 'Name', [
            { required: true, message: 'This field is required!' },
          ], 'large')
        }
        
        {renderSelect('translator_name', 'Translator', [
            { required: true, message: 'This field is required!' },
          ], "", allTranslator, 'single')
        }
        
        {renderSelect('publisher_name', 'Publisher', [
            { required: true, message: 'This field is required!' },
          ], "", allPublisher, 'single')
        }

        {renderSelect('author_name', 'Author', [
            { required: true, message: 'This field is required!' },
          ], "", allAuthor, 'single')
        }

        {renderSelect('book_categories', 'Categories', [
            { required: true, message: 'This field is required!' },
          ], "", allCategory, 'single')
        }

        {/* <Form.Item
            label={"Cover Photo"}
            name="imagefiles"
            rules={[
            {
                required: true,
                message: "This field is required",
            },
            ]}
        >
            <Input type="file" name="imagefiles" onChange={(e)=>handleImageChange(e)}/>
        </Form.Item> */}
        
        {/* <Form.Item
            label={"Upload Book"}
            name="bookfiles"
            rules={[
            {
                required: true,
                message: "This field is required",
            },
            ]}
        >
            <Input type="file" name="bookfiles" onChange={(e)=> handleFileChange(e)} />
        </Form.Item> */}

        <Form.Item
          name="state"
          label="Type"
          rules={[{ required: true, message: 'This field is required!' }]}
        >
          <Select
              showSearch
              placeholder="Select type"
              size="middle"
              allowClear
              mode='single'
              onChange={(e) => setType(e)}
          >
              {state.map(item => (
                  <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Form.Item> 

        {type === 'premium' ?
        <>
          {renderInput('price', 'Price', [
              { required: false, message: 'This field is required!' },
            ], 'large', 'number')
          }
          {/* <Form.Item
              label={"Upload Demo Book"}
              name="bookdemofiles"
              rules={[
              {
                  required: true,
                  message: "This field is required",
              },
              ]}
          >
              <Input type="file" name="bookdemofiles" onChange={(e)=> handleFileChange(e)}  />
          </Form.Item> */}
        
        </>
        :null}

        {renderSelect('language', 'Language', [
            { required: true, message: 'This field is required!' },
          ], "", language, 'single')
        }

        {renderSelect('status', 'Status', [
            { required: false, message: 'This field is required!' },
          ], "", status, 'single')
        }


        {renderInput('total_page', 'Total Page', [
            { required: true, message: 'This field is required!' },
          ], '', 'number')
        }

      {renderDate('store_date', 'Store Date', [
            { required: true, message: 'This field is required!' },
          ], 'large')
        }

        {renderDate('orginal_date', 'Orginal Date', [
            { required: false, message: 'This field is required!' },
          ], 'large')
        }


        {renderTextField('summary', 'Summary', [
            { required: false, message: 'This field is required!' },
          ], 'large')
        }
        
        
         
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
    </Content>
   );
}
 
export default connect(null, {getBook, createBook, updateBook,deleteBook, getBookById, helperAPI})(BookList);