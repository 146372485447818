import React, { Component } from 'react';
// import 'antd/dist/antd.css';
import './index.css';
import { Layout, Menu} from 'antd';
import {
  BookFilled,
  PieChartOutlined,
  UserOutlined,
  AppstoreOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import Logo from './logo.png'; 
const { Sider } = Layout;
const { SubMenu } = Menu;

class Sidebar extends Component {
  state = {
    collapsed: false,
    access: null
  };
componentDidMount = () => {
  this.setState({access: JSON.parse(localStorage.getItem('access'))})
}
  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  render() {
    const { collapsed,access } = this.state;
    return (
        <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse} style={{backgroundColor: '#ffffff'}}>
          <div className="logo" style={{backgroundColor: '#ffffff'}}>
            <img src={Logo} alt="logo" width="30%" />
          </div>
          <Menu theme="light" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1" icon={<PieChartOutlined />}>
            <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>

            {access?.user_management ?
            <SubMenu key="user" icon={<UserOutlined />} title="User Management">
              <Menu.Item key="role"><Link to="/roles">Role</Link></Menu.Item>
              <Menu.Item key="3"><Link to="/users">User</Link></Menu.Item>
            </SubMenu>
            :null}
            {access?.book_management ?
            <SubMenu key="book" icon={<BookFilled />} title="Book Management">
              <Menu.Item key="books"><Link to="/books">Books</Link></Menu.Item>
              <Menu.Item key="bookuser"><Link to="/bookuser">Book user</Link></Menu.Item>
              <Menu.Item key="translator"><Link to="/translator">Translator</Link></Menu.Item>
              <Menu.Item key="author"><Link to="/author">Author</Link></Menu.Item>
              <Menu.Item key="categories"><Link to="/categories">Book Categories</Link></Menu.Item>
              <Menu.Item key="publishers"><Link to="/publishers">Publishers</Link></Menu.Item>
            </SubMenu>
            :null}
            {access?.banner_management ?
            <SubMenu key="banner" icon={<FileImageOutlined />} title="Banner Management">
              <Menu.Item key="banner"><Link to="/banner">Banner</Link></Menu.Item>
            </SubMenu>
            :null}
            {access?.app_management ?
            <SubMenu key="app" icon={<AppstoreOutlined />} title="App Management">
              <Menu.Item key="apps"><Link to="/apps">Apps</Link></Menu.Item>
            </SubMenu>
            :null}
          </Menu>
        </Sider>
    );
  }
}

 
export default Sidebar;