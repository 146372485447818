import axios from 'axios';

export default (token = false) => {
    
    return axios.create({
        baseURL: 'https://api.hidayahbooks.hidayahsmart.solutions/v1/',
        // baseURL: 'http://182.16.156.100s:5000/v1/',
        headers: {
             'content-type': 'application/json',
             
            'authorization': `Bearer ${token}`
        }
    })
}