import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';
import { getRoles } from "../../../actions/role";
import { updateUser,getUsersById } from "../../../actions/user";
import { useEffect, useState} from 'react';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible,user_id,getRoles,getUsersById,updateUser,refresh}) => {
      const [data, setData] = useState(null)
      const [roles, setRoles] = useState(null)
      const [form] = Form.useForm();
      useEffect(async() => {
        const res = await getRoles();
        setRoles(res)
        const result = await getUsersById(user_id);
        setData(result)
              form.setFieldsValue({
                full_name: result?.full_name,
                status: result?.status,
                email : result?.email,
                mobile_number : result?.mobile_number,
                user_id: result?.user_id,
                role : result?.role,
                
              });
      }, [])
      const createRoles = (label,value) => {
        return {label,value}
      }
      const onFinish = async (values) => {
        // console.log('Success:', values);
        const code = await updateUser(values)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createRoles(item.name , item.name ))
      : null)

    return ( 
          
          <Modal
          title="Edit User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          {data ?
              <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{alignSelf: 'center'}}
              requiredMark={false}
              form={form}
              >
                
                {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('full_name', 'Full Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('email', 'Email', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('mobile_number', 'Mobile', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid number!' }
                  ])
                }
                {renderSelect('role', 'Role', [
                    { required: true},
                  ],
                  'Select a option', option, 'none')
                }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              
              </Form>
          :null}
        </Modal>
     );
}
 
export default connect(null,{getRoles,updateUser,getUsersById})(EditModalForm);
