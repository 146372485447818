import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import Spinner from "../../component/spinner";
import history from "../../history";
import Dashboard from "../dashboard/dashboard";
import CreateUser from "../user/createUser";
import RoleList from "../user/role/roleList";
import CreateRole from "../user/role/createRole";
import UserList from "../user/user/userList";
import UserListModal from "../user/userListModal";
import EditRole from "../user/role/editRole";
import TranslatorList from "../book/translator";
import CategoriesList from "../book/categories";
import PublisherList from "../book/publisher";
import AuthorList from "../book/author";
import Booklist from "../book/book/index";
import BookUserList from "../book/user/index";

import AppList from "../App/index";
import BannerList from "../banner";

import './index.css';
const Switcher = ({ loader }) => {
  const [access, setAccess] = useState(null);
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem("access")));
    // socket
  }, []);
  return (
    <div style={{ margin: '10px', backgroundColor: '#FFF', height: '100%'}}>
      {loader ? <Spinner /> : null}
      <switch>
        <Route path="/dashboard" component={Dashboard} />
        {/* <Redirect form="/" to="/dashboard" /> */}
      

        <>
          {/* ==Role== */}
          <Route path="/roles" component={RoleList} />
          <Route path="/create-role" component={CreateRole} />
          <Route path="/edit-role" component={EditRole} />

          <Route path="/books" component={Booklist} />
          <Route path="/bookuser" component={BookUserList} />
          <Route path="/translator" component={TranslatorList} />
          <Route path="/author" component={AuthorList} />
          <Route path="/categories" component={CategoriesList} />
          <Route path="/publishers" component={PublisherList} />

          <Route path="/apps" component={AppList} />

          <Route path="/banner" component={BannerList} />

          <Route path="/createUser" component={CreateUser} />
          <Route path="/userListModal" component={UserListModal} />
          <Route path="/users" component={UserList} />
        </>
      </switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loading,
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(Switcher);
