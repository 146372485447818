import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Breadcrumb, Select, Modal, Pagination, Tooltip, Row, Col } from 'antd';
import Highlighter from 'react-highlight-words';
import { EditOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getBookUsers, getBookUsersById, helperAPI } from "../../../actions/book";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import '../style.css'
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const BookUserList = ({ getBookUsers, getBookUsersById, helperAPI }) => {
  const [ID, setID] = useState('')
  const [data, setData] = useState(null) 
  const [page, setPage] = useState(1)
  const [pageValue, setPageValue] = useState(30)
  const [searchValue, setSearchValue] = useState('')
  const [details, setDetails] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


  const getData = async () => {
    const res = await getBookUsers(page,pageValue,searchValue)
    setData(res);
  }

  useEffect(() => {
    getData()
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };
  const handlePagination = async (e) => {
    setPage(e);
    const res = await getBookUsers(e,pageValue,searchValue)
    setData(res);
  }
  const handleApiSearch = async () => {
    const res = await getBookUsers(page,pageValue,searchValue)
    setData(res);
  }

  const handleView = async (item) => {
    setID(item?.uuid)
    const res =  await getBookUsersById(item?.uuid);
    setDetails(res)
    setIsCreateModalOpen(true)
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };
  
  const createDetails = (id, book_name, book_image, state, download_time, create_at) => {
    return {id, book_name, book_image, state, download_time, create_at};
  };

  const detailsColumns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Book Name',
      dataIndex: 'book_name',
      key: 'book_name',
      width: '20%',
      ...getColumnSearchProps('book_name'),
    },
    {
      title: 'Book Image',
      dataIndex: 'book_image',
      key: 'book_image',
      width: '20%',
      ...getColumnSearchProps('book_image'),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width: '20%',
      ...getColumnSearchProps('state'),
    },
    {
      title: 'Download Time',
      dataIndex: 'download_time',
      key: 'download_time',
      width: '20%',
    },
    {
      title: 'Create at',
      dataIndex: 'create_at',
      key: 'create_at',
      width: '20%',
    },
  ];

  const detailsRows = [
    details
      ? details?.map((item, key) => createDetails(
        key + 1, 
        item?.data?.book_name, 
        <img className='img' src ={`https://api.hidayahbooks.hidayahsmart.solutions/static/book_cover/${item?.data?.book_image}`} width = '30px' />,
        item?.data?.state, 
        item?.data?.download_time, 
        item?.data?.book_create_at,
        ))
      : "",
      
  ];

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
      width: '20%',
      ...getColumnSearchProps('mobile_number'),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      ...getColumnSearchProps('email'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
    },
    {
      title: 'Create at',
      dataIndex: 'create_at',
      key: 'create_at',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const createData = (id, name, mobile_number, email, status, create_at, action) => {
    return {id, name, mobile_number, email, status, create_at, action};
  };

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.data?.name, 
        item?.data?.mobile_number, 
        item?.data?.email, 
        item?.data?.status ? 'Active' : 'Inactive', 
        item?.data?.create_at,
        <Button size="small" type="primary" icon={<EyeOutlined  />} onClick={() => handleView(item?.data)}>View</Button>,
         
        ))
      : "",
      
  ];

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Book User</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Book User"
        subTitle="Book Management"
      >
      </PageHeader>
      <Row>
        <Col xs={11} sm={11} md={7} lg={7} xl={7}>
          <Input value={searchValue} size="middle" placeholder="ID/Name?Mobile " allowClear style={{width:'99%'}} onChange={(e)=>setSearchValue(e.target.value)} />
        </Col>
        
        <Col xs={4} sm={11} md={7} lg={7} xl={7}>
          <Tooltip title="search" onClick={handleApiSearch}>
            <Button type="primary" icon={<SearchOutlined />} />
          </Tooltip>
        </Col>
      </Row>
      <br />  
      <br />  
      <Table columns={columns} pagination={true} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <Pagination defaultCurrent={1} pageSize={pageValue} total={(data?.length + 1)} onChange={(e) => { handlePagination(e); }} />
      <br />
      
      <Modal 
        title="User Details" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={1000}
        footer={null}
      >
        <Table columns={detailsColumns} pagination={false} dataSource={detailsRows[0]} scroll={{ x: 1000 }} sticky/>
      </Modal>
    </Content>
   );
}
 
export default connect(null, {getBookUsers, getBookUsersById, helperAPI})(BookUserList);